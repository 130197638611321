.bottomTabs {
    width: 100%;
    height:70px;
    display: flex;
    justify-content: 'space-evenly';
 align-items: center;
   flex-direction: row;
   background-color: '#fff';
    position: 'absolute';
    top: 0,
  }
  .tab {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: 'space-evenly';
    align-items: center;
  }
  .tabIcon {
    width: 40px;
    height: 40px
  }
  .textInfo{
    font-size: 15px;
 font-weight: 600;
   color: white;
   text-align: center;
   display: flex;
   justify-content: center;
  }
  .bottomTabsPopUp{
    width: 100%;
    height:70px;
    display: flex;
    justify-content: 'space-evenly';
 align-items: center;
   flex-direction: row;
   background-color: '#fff';
    position: 'absolute';
    top: 0,
  }
  .tabPopUp {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: 'space-evenly';
    align-items: center;
  }

  .swal2-container {
    z-index: 9999; /* Adjust this value as needed to make sure it's above your Material-UI Dialog */
  }

  .tab-pseudo-element{
    content: "" !important;
    position: relative !important;
    bottom: -49px !important;
    left: -50% !important;
    border: 15px solid transparent !important;
    border-top-color: #e74c3c !important;
  }
  
  .custom-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .custom-table th,
  .custom-table td {
    border: 1px solid #ccc;
    padding: 8px;
   
  }
  
  .align-center {
    text-align: center;
    background-color: rgba(0, 0, 0, 0.05);
  }


  @media screen and (max-width: 800px) {
    .textInfo {
        font-size: 10px !important;
 font-weight: 300 !important;
    }
    .stats{
        padding:110px 10px !important;
    }
    .bottomTabs {

      height:40px !important;

    }
    .cellText {
        font-size: 8px !important;
 font-weight: 700 !important;
    }
    .share{
      font-size: 8px !important;
 font-weight: 700 !important;
    }
    .playhistorytext{
      font-size: 20px !important;
 font-weight: 500 !important;
    }
    .scorefootertext{
      font-size: 12px !important;
      font-weight: 600 !important;
    }
    .scorefooterimg{
      width: 25px !important;
    }
    .scorecardtext{
      font-size: 20px !important;
    }
    .headertext{
      font-size: 10px !important;
      font-weight: 700 !important;
    }
    .headerinput{
      padding: 0px !important;
      width: 30% !important;
    }
    .footerbutton{
      font-size: 10px !important;
      font-weight: 700 !important;
      padding: 2px 10px !important;
      
    }

  .tab-pseudo-element{
  content: "" !important;
  position: relative !important;
  bottom: -29px !important;
  left: -50% !important;
  border: 9px solid transparent !important;
  border-top-color: #e74c3c !important;
}
.custom-table th,
.custom-table td {
  padding: 0px;
 
}
.align-center {
  font-size: 12px;
}
.header {
  font-size: 10px;
  font-weight: 700;
  padding: 5px 0px;
}
.custom-table th, .custom-table td {
    border: 1px solid #ccc;
    padding: 0px;
  }


.scheduleCard{
    margin: 0px !important;
}




  }


  
  @media screen and (max-width: 350px) {
    .align-center {
      font-size: 9px;
    }
    .header {
      font-size: 8px;
      font-weight: 600;
      padding: 2px 0px;
    }
  }