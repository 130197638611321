.GameScheduleButton{
    color: #fff;
    background-color: #5e72e4;
    border-color: #5e72e4;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    display: inline-block;
    font-weight: 600;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.625rem 1.25rem;
} 
.ChatTitle{
  font-size: 20px;
  padding: 10px;
  margin: 0;
  border-bottom: 1px dashed lightgray;
  font-weight: 600;
}
.ChatTitle2{
  font-size: 20px;
  padding: 10px;
  margin: 0;
  border-bottom: 1px dashed lightgray;
  font-weight: 600;
  border-left: 1px solid #a6a6a6;
}
.chat1{
  background: #f0f0f0;
  /* height: 100%; */
  max-height: 390px;
  overflow: hidden;
  height: 100vh;
}
.chat2{
  flex: 2;
    max-height: 100%;
    height: 470px;
    overflow: hidden;
    border-left: 1px solid #a6a6a6;
}
.textarea{
  width: 96%;
    margin: 10px;
    resize: none;
    border-radius: 3px;
    border: 1px solid lightgray;
    padding: 4px 10px;
    overflow: auto;
    height: 12%;
    font-size: 18px;
}
  @media screen and (max-width: 800px) {
    .GameSchedule {
        margin: 0px !important;
    }
    .title{
        text-align: left;
        padding: 10px;
        /* display: flex; */
        /* justify-content: start;
        align-items: start; */
    }
    .findfriend{
      margin: 0px !important;
      flex-direction: row;
    }
    .findfriend1{
      padding: 0px !important;
    }
   
  }