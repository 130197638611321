.homeContainer{
    /* background-color: red; */
    /* margin-top: 75px; */
    padding: 200px 100px 100px 100px;
    /* padding: 100px; */
    background-image: url("../../Assets/intro-bg.png");
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
    background-attachment: scroll;
    }

    .circle{
        border: 2px solid red;
        border-radius: 50%;
        width: 60px;
        height: 60px;
    }
    .subHeading{
        font-size: 20px;
        font-weight: 600;
    }

    @media screen and (max-width: 600px) {

        .homeContainer{
            padding: 130px 10px 100px 10px !important;
      }
      .homeStatic{
        padding: 20px !important;
      }
      .homeStaticCard{
        padding: 20px !important;
      }
      .ContactStatic{
        padding: 0px !important;
      }
      .Cards{
        padding: 10px 0px !important;
      }
    }