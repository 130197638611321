.row {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px 8px;
}
.cell {
    display: flex;
    border-right:'1px solid red'
  }
  .cellText {
    font-size: 13px;
    color: black;
    font-weight: bold;
  }
  .footerbutton{
    font-weight: 500 !important;
  }
  .footerbutton:hover{
    opacity: 0.5;
  }
  .scorefootertext{
   text-decoration: none;
  }
  /* .tab::after{
    content: "" !important;
    position: relative !important;
    bottom: -50px !important;
    left: -30% !important;
    border: 15px solid transparent !important;
    border-top-color: #e74c3c !important;
  } */

.tab-pseudo-element{
  content: "" !important;
  position: relative !important;
  bottom: -49px !important;
  left: -50% !important;
  border: 15px solid transparent !important;
  border-top-color: #e74c3c !important;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
}

.custom-table th,
.custom-table td {
  border: 1px solid #ccc;
  padding: 8px;
 
}

.align-center {
  text-align: center;
  background-color: rgba(0, 0, 0, 0.05);
}



  @media screen and (max-width: 800px) {
    .cellText {
        font-size: 8px !important;
 font-weight: 700 !important;
    }
    .share{
      font-size: 8px !important;
 font-weight: 700 !important;
    }
    .playhistorytext{
      font-size: 20px !important;
 font-weight: 500 !important;
    }
    .scorefootertext{
      font-size: 12px !important;
      font-weight: 600 !important;
    }
    .scorefooterimg{
      width: 25px !important;
    }
    .scorecardtext{
      font-size: 20px !important;
    }
    .headertext{
      font-size: 10px !important;
      font-weight: 700 !important;
    }
    .headerinput{
      padding: 0px !important;
      width: 30% !important;
    }
    .footerbutton{
      font-size: 10px !important;
      font-weight: 700 !important;
      padding: 2px 10px !important;
      
    }

  .tab-pseudo-element{
  content: "" !important;
  position: relative !important;
  bottom: -29px !important;
  left: -50% !important;
  border: 9px solid transparent !important;
  border-top-color: #e74c3c !important;
}
.custom-table th,
.custom-table td {
  padding: 0px;
 
}
.align-center {
  font-size: 12px;
}
.header {
  font-size: 10px;
  font-weight: 700;
  padding: 5px 0px;
}
.custom-table th, .custom-table td {
    border: 1px solid #ccc;
    padding: 0px;
  }







  }

  @media screen and (max-width: 350px) {
    .align-center {
      font-size: 9px;
    }
    .header {
      font-size: 8px;
      font-weight: 600;
      padding: 2px 0px;
    }
  }