.gameInstContainer{
 
        background-color:red;
        /* background-image: linear-gradient(#FF5722, #9C27B0); */
        background: linear-gradient(87deg, #FF5722 0, #9C27B0 100%) !important;
        /* margin-top: 75px; */
        padding: 150px 50px 50px 50px;
        /* padding: 100px; */
        /* background-image: url("../../Assets/intro-bg.png"); */
        width: 100% !important;
        /* height: 100cqb !important; */
        height: 100vh;
        background-size: 100% 100% !important; 
        background-repeat: no-repeat;
       
        background-attachment: scroll; 
}

.waiting_room_container{
 
        background-color:red;
        /* background-image: linear-gradient(#FF5722, #9C27B0); */
        background: linear-gradient(87deg, #FF5722 0, #9C27B0 100%) !important;
        /* margin-top: 75px; */
        padding: 100px 50px 50px 50px;
        /* padding: 100px; */
        /* background-image: url("../../Assets/intro-bg.png"); */
        width: 100% !important;
        /* height: 100cqb !important; */
        /* height: 100vh; */
        background-size: 100% 100% !important; 
        background-repeat: no-repeat;
       
        background-attachment: scroll; 
}

.css-tv9z72-MuiGrid-root{
        height: 100% !important;
}
.css-1tw2i9j-MuiGrid-root{
        height: 30% !important;
}

@media screen and (max-width: 800px) {
        .gameInstContainer{

          padding: 70px 15px 15px 20px !important;
        
      }
      .Gameheader{
        font-size: 22px;
      }
}



/* Scrollbar Styling */
::-webkit-scrollbar {
        width: 10px;
    }
     
    ::-webkit-scrollbar-track {
        background-color: #ebebeb;
        -webkit-border-radius: 10px;
        border-radius: 10px;
    }
    
    ::-webkit-scrollbar-thumb {
        -webkit-border-radius: 10px;
        border-radius: 10px;
        background: #9C27B0; 
    }
