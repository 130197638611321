.bottomTabs {
    width: 100%;
    height:70px;
    display: flex;
    justify-content: 'space-evenly';
 align-items: center;
   flex-direction: row;
   background-color: '#fff';
    position: 'absolute';
    top: 0,
  }
  .tab {
    width: 25%;
    height: 100%;
    display: flex;
    justify-content: 'space-evenly';
    align-items: center;
  }
  .tabIcon {
    width: 40px;
    height: 40px
  }
  .textInfo{
    font-size: 15px;
 font-weight: 600;
   color: white;
   text-align: center;
   display: flex;
   justify-content: center;
  }
  .bottomTabsPopUp{
    width: 100%;
    height:70px;
    display: flex;
    justify-content: 'space-evenly';
 align-items: center;
   flex-direction: row;
   background-color: '#fff';
    position: 'absolute';
    top: 0,
  }
  .tabPopUp {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: 'space-evenly';
    align-items: center;
  }

  .swal2-container {
    z-index: 9999; /* Adjust this value as needed to make sure it's above your Material-UI Dialog */
  }

  @media screen and (max-width: 800px) {
    .textInfo {
        font-size: 10px !important;
 font-weight: 300 !important;
    }
    .stats{
        padding:110px 10px !important;
    }
    .bottomTabs {

      height:40px !important;

    }
    
  }