@media screen and (max-width: 800px) {

    .mypostcontainer{
padding: 100px 20px 20px 20px !important;
  }
  .postDetails{
    font-size: 16px;
  }
  .buttonmypost{
    margin: 20px 20px 0px 0px;
  }
  .textarea{
    width: 100px;
  }
}