.QuestionContainer{
    /* background-color:red;
        background-image: linear-gradient(#2dce89, #2dcecc); */
        /* margin-top: 75px; */
        padding: 100px 50px 307px 50px;
        /* padding: 102.2px; */
        /* background-image: url("../../Assets/intro-bg.png"); */
        width: 100% !important;
        height: 100cap !important;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-attachment: scroll;
        /* margin-left: 0px !important; */
}

@media screen and (max-width: 800px) {
    .QuestionContainer{

      padding: 20px !important;
    
  }
}

/* Style for flip cards */
.card {
  text-align: center;
  font-size: 25px;
}

.flip-card {
  display: flex;
  flex-direction: column;
  box-shadow: 0 7px 3px 0 var(--box-shadow);
  position: relative;
  margin: 15px auto;
  perspective: 1000px; /* Enable 3D perspective for flip animation */
}

.top-half,
.bottom-half {
  font-size: 35px;
  height: 43px;
  width: 77px;
  padding: 23px 7px;
  overflow: hidden;
  text-align: center;
  will-change: transform !important;
  box-shadow: 0 3px rgba(71, 83, 115, 0.2) inset;
  transform-style: preserve-3d; /* Enable 3D transformations */
  transition: transform 0.5s ease-in-out; /* Define flip animation duration */
}

.top-half {
  border-radius: 5px 5px 0 0;
  background-color: var(--top-card);
}

.bottom-half {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  border-radius: 0 0 5px 5px;
  background-color: var(--desatBlue);
  box-shadow: 0 -2px 5px 0 rgba(71, 83, 115, 0.7) inset;
  transform: rotateX(180deg); /* Initially flip to the bottom */
}

/* Apply flip animation when the flipping class is added */
.flipping {
  transform: rotateX(0deg);
}

/* Customize these variables as needed */
:root {
  --box-shadow: 0 7px 3px 0 rgb(21, 21, 21);
  --top-card: rgb(44, 44, 68);
  --desatBlue: hsl(236, 21%, 26%);
  /* Define other custom properties here */
}
