.footer-heading{
    color: #ffff;
}
.footer-para{
    color: #ffff;
}

@media screen and (max-width: 800px) {
    .footer {
      /* font-size: 24px; */
      /* background-color: red !important; */
      padding: 20px !important;
    }
  }